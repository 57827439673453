import './styles.scss'
import Swiper from 'swiper/dist/js/swiper.js'
import SweetScroll from 'sweet-scroll'
import axios from 'axios'
import '@babel/polyfill'

const mySwiper = new Swiper('.swiper-container', {
  // Optional parameters
  autoplay: {
    delay: 3000,
  },
  speed: 3000,
  slidesPerView: 'auto',
  centeredSlides: true,
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    1024: {
      slidesPerView: 'auto',
    },
    768: {
      slidesPerView: 'auto',
    },
    640: {
      slidesPerView: 1,
    },
    320: {
      slidesPerView: 1,
    },
  },
})

const scroller = new SweetScroll()

const floormap = document.getElementById('floormap')
if (floormap) {
  floormap.addEventListener('load', () => {
    const doc = floormap.contentDocument
    const areas = doc.querySelectorAll('[id^=area]')
    console.log(areas)
    for (const a of areas) {
      a.style.cursor = 'pointer'
      a.style.transition = 'all 150ms ease'
      a.addEventListener('mouseover', (ev) => {
        ev.target.style.opacity = '0.7'
      })
      a.addEventListener('mousedown', (ev) => {
        ev.target.style.opacity = '1.0'
      })
      a.addEventListener('mouseup', (ev) => {
        ev.target.style.opacity = '0.7'
        const targetID = `#heading-${a.id}`
        scroller.to(targetID)
        console.log(targetID)
      })
      a.addEventListener('mouseout', (ev) => {
        ev.target.style.opacity = '0.5'
      })
    }
    const nums = doc.querySelectorAll('[id^=num ]')
    for (const n of nums) {
      n.style.pointerEvents = 'none'
    }
  })
}

const toTop = document.getElementById('toTop')
if (toTop) {
  toTop.addEventListener('click', () => {
    scroller.to(0)
  })

  window.addEventListener('scroll', () => {
    if (window.scrollY > 450) {
      toTop.classList.add('is-active')
    } else {
      toTop.classList.remove('is-active')
    }
  })
}

let sheetId = '1C1NQIxHfcxWF2zG5D6xEgltuF_9LtU_Huz6m2tnDqN4'
if (process.env.NODE_ENV === 'production') {
  sheetId = '1vS9xdPKNo7kCIqnUrbSvBXFrAAVr0l8ICfhb-3YqCa8'
}

if (window.location.pathname === '/') {
  document.addEventListener('DOMContentLoaded', () => {
    axios(
      `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/news!A3:E50?key=AIzaSyAzPTlC5ffArI8JdMiMCJq_fIXARtQb66o`,
    )
      .then((response) => {
        if (response.data.values) {
          let rows = response.data.values.sort((c, p) => {
            const currentDate = new Date(c[0]).getTime()
            const previousDate = new Date(p[0]).getTime()
            return previousDate - currentDate
          })
          rows = rows.filter((r) => {
            if (r[1] === '') {
              return true
            }
            const now = new Date().getTime()
            const rowDate = new Date(r[1]).getTime()
            return rowDate > now
          })
          for (const r of rows) {
            const [newsDate, dueDate, imageFile, targetFloor, newsText] = r
            const textLines = newsText.split('\n')
            const newsItemContainer = document.createElement('div')
            newsItemContainer.className = 'newsItemContainer'
            newsItemContainer.innerHTML = `
          <div class="newsItem">
            <figure class="image is-96x96">
              <img
                src="/img/news/${imageFile}"
                onerror="this.onerror=null;this.src='/img/noimage-news.svg';this.srcset='/img/noimage-news.svg 1x,/img/noimage-news.svg 2x,/img/noimage-news.svg 3x';"
                />
            </figure>
            <div class="newsRight is-vertical">
              <div class="date">
                ${newsDate}
              </div>
              <div class="body">
                ${textLines.join('<br />')}
              </div>
              <div class="floor">
                ${targetFloor}
              </div>
            </div>
          </div>
        `
            const newsList = document.querySelector('.levContainer.news > .columns')
            newsList.appendChild(newsItemContainer)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  })
}
